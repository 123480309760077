@import "../../../styles/variable.module.scss";
.discoverIndex2 {
  overflow: hidden;
  .loadmoreBtn {
    @include medium_device {
      display: none;
    }
  }
  .discover__slider {
    [class*="-next"],
    [class*="-prev"] {
      @include medium_device {
        bottom: auto;
        top: -100px;
      }
    }
    [class*="-prev"] {
      @include medium_device {
        left: auto;
      }
    }
    [class*="-list"] {
      margin: 0;
      & > div {
        & > div {
          .discover__card {
            text-align: center;
          }
        }
      }
    }
  }
  .nftRows{
    @media only screen and (min-width: 1200px){
      margin: 0 -15px;
    }
    @include medium_device{
      margin: 0 -8px 15px;
      > div{
        padding: 0 8px;
      }
    }
  }
  [class$=grid-column-4]{
    @include medium_device{
      grid-template-columns: auto auto;
      gap: 16px;
    }
  }
}
.discoverMain{
  [class*="card-header"]{
    [class*="-sm-8"] {
      display: none;
    }
    [class*="-sm-4"] {
      @include large_device{
        width: 100%;
        [class*="selectMenu"]{
          margin-bottom: 0 !important;
        }
      }
    }
  }
  @media only screen and (min-width: 992px){
    [class*="card-header"]{
      [class*="-sm-4"] {
        width: 100%;
        padding: 0 12px;
        [class*="mySwiper"]{
          width: 100%;
          height: 100%;
          [class*="swiper-slide"]:not([class~="active"]){
            color: var(--light-font);
          }
        }
        [class*=button-prev],
        [class*=button-next]{
          position: static;
          margin: auto 0 auto 5px;
          flex: 0 0 auto;
          transform: translateY(0);
        }
        [class*=button-prev]{
          transform: rotate(180deg) translateY(0);
        }
      }
      [class*="-sm-8"] {
        width: auto;
        min-width: 160px;
        display: none;
      }
    }
  }
}
.hot__title {
  margin-bottom: 48px !important;
  @include medium_device {
    margin-bottom: 20px !important;
    font-size: 32px;
  }
  @include mobile_device {
    font-size: 24px;
  }
}
.customSection {
  padding: 0;
  .accordion {
    margin-top: 0;
  }
}
.loadmoreBtn {
  @include mobile_device {
    width: 250px;
  }
  &:hover {
    & > div {
      & > span {
        & > span {
          background-color: var(--background-color);
        }
      }
    }
  }
}

[dir="rtl"] {
  .discoverMain{
    [class*=card-header]{
      [class*=-sm-4]{
        [class*=button-prev],
        [class*=button-next]{
          margin: auto 5px auto 0;
          transform: translateY(0) rotate(0);
        }
        [class*=button-next]{
          order: 2;
          transform: translateY(0) rotate(-180deg);
        }
      }
    }
  }
}